<template>
  <layout name="Default">
    <authorize :permission="$route.meta.permission || true">
      <div class="Newsroom">
        <router-view :key="$route.name" />
      </div>
    </authorize>
  </layout>
</template>

<script>
import _get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import Notification from 'element-ui/lib/notification'

import store from '@/store/index'

import { Trans } from '@/services/TranslationService'
import { displayRequestError } from '@hypefactors/shared/js/utils'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'

async function setBrandAsActiveFromQueryParameter (to) {
  let brand = _get(to, 'query.brand', null)
  if (!brand) return
  if (!store.getters.brands.find(b => b.id === brand)) throw Error('Brand id not present in user brands')
  await store.dispatch('setActiveBrand', brand)
}

export default {
  async beforeRouteEnter (to, from, next) {
    if (!store.getters.brands.length) return next(false)
    // if we are already fetching it, just return. Not calling next will never finish the action.
    if (store.state.newsroom.isFetchingBrandData) return
    try {
      // Notify the store we are starting to fetch data
      store.commit('newsroom/SET_FETCHING_BRAND_DATA', true)

      await setBrandAsActiveFromQueryParameter(to)

      if (store.getters['newsroom/isCurrentFetchedDataRelevant']()) {
        return next()
      }
      // Fetch the newsroom
      await store.dispatch('newsroom/fetchNewsrooms')
      await Promise.all([
        store.dispatch('newsroom/fetchCategories'),
        store.dispatch('newsroom/fetchCategoriesFlat')
      ])
      store.dispatch('newsroom/fetchAttachedBrandData')
      // all good, continue
      return next()
    } catch (err) {
      // pass the notification object as we cant access the Vue Instance here.
      displayRequestError(err, Trans.t('errors.cannot_fetch_newsroom_data'), 5000, undefined, Notification)
      next({ name: 'dashboard.dashboard' })
    } finally {
      store.commit('newsroom/SET_FETCHING_BRAND_DATA', false)
    }
  },
  computed: {
    ...mapGetters('newsroom', ['appliedNewsroomFilters'])
  },
  watch: {
    activeBrandId: generateUniqueWatcher('fetchData')
  },
  methods: {
    ...mapActions('newsroom', ['fetchAttachedBrandData', 'fetchNewsrooms']),
    async fetchData () {
      try {
        await this.fetchNewsrooms()
        this.fetchAttachedBrandData()
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
